import { fadeIn } from '@pernod-ricard-global-cms/jsanimations';

export default function siteHeaderJs(options = {}) {
	try {
		// CSS for site-header should usually be included in critical.scss
		const { block } = options;

		// fadeIn(block);
		const documentBody = document.body;
		const header = document.querySelector('.site-header-2');
		const navContainer = document.querySelector('.header-nav');
		const navContainerInner = navContainer.querySelector(
			'.header-nav__container'
		);
		const hamburgerWrapper = document.querySelector(
			'.header-interactions__hamburger-wrapper'
		);
		const hamburger = document.querySelector(
			'.header-interactions__hamburger'
		);
		const hamburgerBars = hamburger.querySelectorAll(
			'.header-interactions__hamburger-bar'
		);
		const crossBars = document.querySelectorAll(
			'.nav-interactions__cross-bar'
		);
		// const itemsWithChildren = navContainer.querySelectorAll(
		// 	'.header-nav__item.menu-item-has-children'
		// );
		const itemsWithChildren = navContainer.querySelectorAll(
			'li.menu-item-has-children'
		);

		const navWrapper = document.querySelector(
			'.site-header-2__nav-wrapper'
		);
		const closeMenu = document.querySelector('.nav-interactions__cross');

		const closeSubnavs = () => {
			hamburger.classList.remove('active');
			navWrapper.classList.remove('active');
			hamburgerBars.forEach((item) => {
				item.classList.remove('active');
			});
			crossBars.forEach((item) => {
				item.classList.remove('active');
			});
			navContainer
				.querySelectorAll('.header-nav__item.active')
				.forEach((el) => el.classList.remove('active'));
			documentBody.classList.remove('menu-active');
		};

		const toggleMenu = () => {
			hamburger.classList.toggle('active');
			navWrapper.classList.toggle('active');
			hamburgerBars.forEach((item) => {
				item.classList.toggle('active');
			});
			crossBars.forEach((item) => {
				item.classList.toggle('active');
			});
			documentBody.classList.toggle('menu-active');
		};

		const wrapperClick = (event) => {
			if (
				event.target.classList.contains('site-header-2__nav-wrapper') &&
				event.target.classList.contains('active')
			) {
				toggleMenu();
			}
		};

		hamburgerWrapper.addEventListener('click', toggleMenu);
		closeMenu.addEventListener('click', toggleMenu);
		navWrapper.addEventListener('click', wrapperClick);

		const toggleAccordion = (item, isSubNavItem) => {
			const parentNavContainer = isSubNavItem ? item : null;
			const subNavContainer = isSubNavItem
				? item.querySelector('.header-nav__sub-sub-nav-container')
				: item.querySelector('.header-nav__sub-nav-container');
			const isOpen = subNavContainer.offsetHeight > 0;
			const sectionHeight = subNavContainer.scrollHeight;
			if (isOpen) {
				subNavContainer.animate(
					[
						{
							height: sectionHeight + 'px'
						},
						{ height: '0px' }
					],
					{
						fill: 'forwards',
						duration: 400,
						easing: 'ease-in-out'
					}
				);
				if (parentNavContainer) {
					parentNavContainer.animate(
						[
							{
								height: parentNavContainer.scrollHeight + 'px'
							},
							{
								height:
									parentNavContainer.scrollHeight -
									sectionHeight +
									'px'
							}
						],
						{
							fill: 'forwards',
							duration: 400,
							easing: 'ease-in-out'
						}
					);
				}
			} else {
				subNavContainer.animate(
					[
						{ height: '0px' },
						{
							height: sectionHeight + 'px'
						}
					],
					{
						fill: 'forwards',
						duration: 400,
						easing: 'ease-in-out'
					}
				);
				setTimeout(() => {
					parentNavContainer.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
				}, 350);
				if (parentNavContainer) {
					parentNavContainer.animate(
						[
							{
								height: parentNavContainer.scrollHeight + 'px'
							},
							{
								height:
									parentNavContainer.scrollHeight +
									sectionHeight +
									'px'
							}
						],
						{
							fill: 'forwards',
							duration: 400,
							easing: 'ease-in-out'
						}
					);
				}
			}
		};
		itemsWithChildren.forEach((item) => {
			const isSubNavItem = item.classList.contains(
				'header-nav__sub-nav-item'
			);
			const dropDown = isSubNavItem
				? item.querySelector('.header-nav__item-drop-down-small')
				: item.querySelector('.header-nav__item-drop-down');
			dropDown.addEventListener('click', function (e) {
				e.preventDefault();
				toggleAccordion(item, isSubNavItem);
				item.classList.toggle('active');
				dropDown.classList.toggle('active');
			});
		});

		const detectScrolling = () => {
			if (
				window.scrollY > 10 &&
				!header.classList.contains('scrolling')
			) {
				header.classList.add('scrolling');
			} else if (
				window.scrollY < 10 &&
				header.classList.contains('scrolling')
			) {
				header.classList.remove('scrolling');
			}
		};

		window.addEventListener('scroll', detectScrolling, false);

		// window.addEventListener('resize', closeSubnavs, true);
	} catch (error) {
		console.error(error);
	}
}
